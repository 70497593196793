import {CSSObject} from '@emotion/react';

import {COLORS} from '../const/appPalette';
export const globalStyles = [
  {
    '*, *::before, *::after': {
      boxSizing: 'border-box',
      fontFamily: 'Inter',
    },
    '#root': {
      minHight: '100vh',
      transition: 'all .3s',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '100',
      src: 'url("/fonts/inter-thin.woff2") format("woff2"),' + 'url("/app/fonts/inter-thin.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontWeight: '100',
      src:
        'url("/fonts/inter-thin-italic.woff2") format("woff2"),' +
        'url("/fonts/inter-thin-italic.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '200',
      src:
        'url("/fonts/inter-extra-light.woff2") format("woff2"),' +
        'url("/fonts/inter-extra-light.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontWeight: '200',
      src:
        'url("/fonts/inter-extra-light-italic.woff2") format("woff2"),' +
        'url("/fonts/inter-extra-light-italic.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '300',
      src: 'url("/fonts/inter-light.woff2") format("woff2"),' + 'url("/app/fonts/inter-light.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontWeight: '300',
      src:
        'url("/fonts/inter-light-italic.woff2") format("woff2"),' +
        'url("/fonts/inter-light-italic.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      src: 'url("/fonts/inter-regular.woff2") format("woff2"),' + 'url("/fonts/inter-regular.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontWeight: '400',
      src:
        'url("/fonts/inter-regular-italic.woff2") format("woff2"),' +
        'url("/fonts/inter-regular-italic.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      src: 'url("/fonts/inter-medium.woff2") format("woff2"),' + 'url("/fonts/inter-medium.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontWeight: '500',
      src:
        'url("/fonts/inter-medium-italic.woff2") format("woff2"),' +
        'url("/fonts/inter-medium-italic.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600',
      src: 'url("/fonts/inter-semi-bold.woff2") format("woff2"),' + 'url("/fonts/inter-semi-bold.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontWeight: '600',
      src:
        'url("/fonts/inter-semi-bold-italic.woff2") format("woff2"),' +
        'url("/fonts/inter-semi-bold-italic.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '700',
      src: 'url("/fonts/inter-bold.woff2") format("woff2"),' + 'url("/fonts/inter-bold.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontWeight: '700',
      src:
        'url("/fonts/inter-bold-italic.woff2") format("woff2"),' +
        'url("/fonts/inter-bold-italic.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '800',
      src:
        'url("/fonts/inter-extra-bold.woff2") format("woff2"),' + 'url("/fonts/inter-extra-bold.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontWeight: '800',
      src:
        'url("/fonts/inter-extra-bold-italic.woff2") format("woff2"),' +
        'url("/fonts/inter-extra-bold-italic.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '900',
      src: 'url("/fonts/inter-black.woff2") format("woff2"),' + 'url("/fonts/inter-black.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Druk Wide Cyr',
      fontStyle: 'bold',
      fontWeight: '900',
      src:
        'url("/fonts/drukwidecyr-bold.woff2") format("woff2"),' + 'url("/fonts/drukwidecyr-bold.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontWeight: '900',
      src:
        'url("/fonts/inter-black-italic.woff2") format("woff2"),' +
        'url("/fonts/inter-black-italic.woff") format("woff")',
    },

    '::-webkit-scrollbar': {
      backgroundColor: 'white',
      width: 8,
      height: 8,
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: COLORS.SCROLLBAR,
      borderRadius: 8,
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: COLORS.SCROLLBAR_HOVERED,
    },
    body: {
      padding: '0 calc(8px - (100vw - 100%)) 0 0',
    },
  },
] as CSSObject[];
