import React from 'react';
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom';

import * as Sentry from '@sentry/react';

const initializeSentry = () => {
  if (import.meta.env.MODE === 'development') return;

  Sentry.init({
    dsn: import.meta.env.WEB_SENTRY_DSN,
    replaysOnErrorSampleRate: 0.1,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    environment: import.meta.env.WEB_ENV,
  });
};

export default initializeSentry;
