import {BrowserRouter} from 'react-router-dom';

import {
  AppContainer,
  AppRouter,
  AuthWrapper,
  ChatraInitialize,
  ErrorBoundaryWrapper,
  MaintenanceMode,
  UserOnboardingTour,
} from './components';
import {StoreProvider} from './stores';

import {ConfirmationDialogProvider} from './components/providers/confirm-dialog/confirm-dialog';
import {ToastContainer} from './components/providers/toast-container/toast-container';
import {WebSocketProvider} from './components/providers/websocket/websocket-provider';
import initializeDayjs from './shared/configs/dayjsConfig';
import initializeSentry from './shared/configs/sentryConfig';
import {WEBSOCKET_PRIVATE_CHANNEL, WEBSOCKET_PUBLIC_CHANNEL} from './shared/const/websocket';
import {EmotionProvider} from './shared/lib';
import {globalStyles} from './shared/styles/globalStyles';

initializeSentry();
initializeDayjs();

function App() {
  return (
    <ErrorBoundaryWrapper>
      <EmotionProvider withNormalize global={globalStyles}>
        <BrowserRouter>
          <StoreProvider>
            <UserOnboardingTour>
              <ChatraInitialize />
              <MaintenanceMode>
                <WebSocketProvider urls={{private: WEBSOCKET_PRIVATE_CHANNEL, public: WEBSOCKET_PUBLIC_CHANNEL}}>
                  <ToastContainer />
                  <AuthWrapper>
                    <ConfirmationDialogProvider>
                      <AppContainer>
                        <AppRouter />
                      </AppContainer>
                    </ConfirmationDialogProvider>
                  </AuthWrapper>
                </WebSocketProvider>
              </MaintenanceMode>
            </UserOnboardingTour>
          </StoreProvider>
        </BrowserRouter>
      </EmotionProvider>
    </ErrorBoundaryWrapper>
  );
}

export default App;
