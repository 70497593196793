import {ToastContainer as Toaster} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import {useEmotionWrapper} from './toast-container.s';

export const ToastContainer = () => {
  const {classes} = useEmotionWrapper();

  return (
    <Toaster
      className={classes.customStyle}
      autoClose={4500}
      progressClassName={classes.root}
      position="bottom-right"
    />
  );
};
