import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    background: COLORS.PURPLE_100,
  },
  customStyle: {
    '& .Toastify__toast': {
      color: COLORS.DARK_50,
      fontSize: 14,
      fontWeight: 500,
      textAlign: 'justify',
      '& .Toastify__toast-container': {
        width: 500,
      },
      '& .Toastify__toast-body': {
        padding: 6,
      },
    },
  },
}));
