import {createContext, FC, useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {WEBSOCKET_CHANNELS} from '@shared/const/websocket';
import {getJWTToken} from '@shared/utils/getJWTToken';

import {useStore} from '../../../stores';

type TSocketKeys = 'private' | 'public';
const RECONNECT_INTERVAL = 10000;
interface IWebSocketProvider {
  urls: {private: string; public: string};
  children: React.ReactNode;
}

type TWebSocketContext = {private: WebSocket | null; public: WebSocket | null};

const WebSocketContext = createContext<TWebSocketContext>({
  private: null,
  public: null,
});

export const WebSocketProvider: FC<IWebSocketProvider> = observer(({urls, children}) => {
  const {user} = useStore();
  const [sockets, setSockets] = useState<TWebSocketContext>({
    private: null,
    public: null,
  });

  const setupWebSocket = (socketKey: TSocketKeys) => {
    const socket = new WebSocket(urls[socketKey]);

    socket.onopen = () => {
      if (socketKey === WEBSOCKET_CHANNELS.PRIVATE) {
        const authToken = getJWTToken().accessToken;

        if (authToken) {
          socket.send(authToken);
        }
      }
      console.log(`WebSocket (${socketKey}) connected`);
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log(`WebSocket (${socketKey}) closed, code=${event.code}, reason=${event.reason}`);
      } else {
        console.error(`WebSocket (${socketKey}) connection closed unexpectedly`);
      }

      setSockets((prev) => ({...prev, [socketKey]: null}));

      setTimeout(() => setupWebSocket(socketKey), RECONNECT_INTERVAL);
    };

    socket.onerror = (error: Event) => {
      console.error(`WebSocket (${socketKey}) error`, error);
    };

    setSockets((prev) => ({...prev, [socketKey]: socket}));
  };

  const closeWebSockets = () => {
    Object.values(sockets).forEach((socket) => {
      socket?.close();
    });
  };

  useEffect(() => {
    if (user?.isAuth) {
      setupWebSocket(WEBSOCKET_CHANNELS.PRIVATE);
      setupWebSocket(WEBSOCKET_CHANNELS.PUBLIC);
    }

    return () => closeWebSockets();
  }, [urls, user?.isAuth]);

  return <WebSocketContext.Provider value={sockets}>{children}</WebSocketContext.Provider>;
});

export function useWebSocket() {
  return useContext(WebSocketContext);
}
