export const WEBSOCKET_PRIVATE_CHANNEL = `${import.meta.env.WEB_WEBSOCKET_BASE_URL}/private`;
export const WEBSOCKET_PUBLIC_CHANNEL = `${import.meta.env.WEB_WEBSOCKET_BASE_URL}/public`;

export const WEBSOCKET_CHANNELS = {
  PRIVATE: 'private',
  PUBLIC: 'public',
} as const;

export const WEBSOCKET_ACTION_TYPE = {
  ACCOUNT_RELOAD: 'account_reload',
  ITEM_FEE: 'item_fee',
  REPRICER_RECOMMENDED: 'repricer_recommended',
  RULES_REVISION: 'rules_revision',
};
